import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from 'src/components/common';
import { ListPage } from 'src/components/post';
import type { PageProps } from 'gatsby';
import type { GetEventPostsQuery } from 'types/graphql-type';

type Props = PageProps<GetEventPostsQuery>;

export const query = graphql`
  query GetEventPosts {
    posts: allPrismicPost(
      limit: 100
      sort: { fields: [data___date], order: DESC }
    ) {
      ...PostList
    }
  }
`;

export const Event: React.FC<Props> = ({
  data: { posts },
  location: { pathname },
}) => (
  <>
    <SEO title={'開催プログラム'} pathname={pathname} />
    <ListPage posts={posts} />
  </>
);

export default Event;
